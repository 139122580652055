import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";

const ResolutionCard = ({ title, description, backgroundImage, id }) => {
  const cardRef = useRef(null);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (cardRef.current) {
      html2canvas(cardRef.current, { scale: 3 }).then((canvas) => {
        setImageURL(canvas.toDataURL("image/png"));
      });
    }
  }, []);

  if (imageURL) {
    return <img src={imageURL} id={id} alt="Resolution Card" />;
  }

  return (
    <div
      ref={cardRef}
      className="flex h-[800px] justify-center items-center"
      style={{
        aspectRatio: "9 / 16",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <section className="py-8 px-16" style={{ zIndex: 1 }}>
        <article
          className="bg-white flex flex-col p-6 pt-3 rounded-lg"
          style={{ background: "rgba(255, 255, 255, 0.5)" }}
        >
          <div className="flex flex-col items-center mt-6">
            <div style={{ width: "65px", height: "50px" }}>
              <img
                src="/images/logo.png"
                alt="1440 logo"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <div className="bg-gray-900 text-white flex mb-2 items-start text-xs px-3 pt-0 pb-2.5 rounded font-semibold">
              My 2024 Resolution
            </div>

            <h1 className="text-3xl font-bold text-center">{title}</h1>
            <p className="text-md text-center">
              {description.length > 200
                ? `${description.slice(0, 200)}...`
                : description}
            </p>
          </div>
        </article>
        <article className="bg-gray-900 text-white text-xs px-3 pt-0 pb-2.5 rounded mt-3">
          <div className="flex justify-between font-semibold items-center">
            <span>#1440AiResolutionMaker</span>
            <span>www.1440.ai</span>
          </div>
        </article>
        <article className="bg-gray-900 rounded-lg mt-3"></article>
      </section>
    </div>
  );
};

export default ResolutionCard;
