import React from "react";
import Header from "./Header";

const BasicLanding = () => {
  return (
    <div className="bg-black min-w-screen">
      <div className="bg-black w-full min-h-screen text-white py-12 sm:py-24 px-4 sm:px-6 lg:px-8 mx-auto max-w-3xl">
        <Header />
        <div className="">
          <h1 className="text-xl mt-16 font-bold mb-6 text-white">
            Advancing the Science of Purposeful Living
          </h1>
          <p className="mb-6 text-xl text-gray-300">
            1440 is pioneering a new paradigm in human development through the
            fusion of artificial intelligence and behavioral science.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            Our mission is to empower millions to live more purposefully by
            aligning their time with their deepest aspirations.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            We've developed an enhanced performance coach that integrates with
            your digital ecosystem, analyzing data from wearables, calendars,
            and health records to deliver personalized, science-backed
            interventions.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            Our approach is rooted in cutting-edge research on human
            flourishing, chronobiology, and behavioral change.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            We've developed proprietary models that optimize various aspects of
            well-being.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            All viewed through the lens of individual purpose and time
            allocation.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            1440's innovation extends beyond traditional coaching methods:
          </p>
          <p className="mb-6 text-xl text-gray-300">
            We've reimagined the calendar as a dynamic tool for personal growth,
            helping you make the most of your time in alignment with your
            purpose.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            At the core of our platform is an AI-first coach that adapts to your
            needs. This intelligent system leverages human coaches, field
            experts, and peer groups when relevant, enhancing the AI's
            capabilities.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            The coach interacts through calls, texts, voicemails, images, and
            your calendar.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            Our team of experts in human performance, chronobiology, positive
            psychology, and machine learning are continuously refining our
            methodologies to push the boundaries of what's possible in personal
            development.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            By partnering with thought leaders and celebrities, we're scaling
            transformative coaching methodologies, making cutting-edge personal
            development accessible and affordable.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            Join us as we redefine the science of purposeful living and unlock
            human potential at an unprecedented scale.
          </p>
          <p className="mb-6 text-xl text-gray-300">
            The future of personal development is here. And it's precisely
            calibrated to you.
          </p>
          <p className="text-lg tex-white font-medium mb-3 mt-8 border-t border-slate-900 pt-2"></p>

          <div className="flex flex-col sm:flex-row justify-start items-center space-x-2">
            <p className="font-medium text-xl text-white mb-4 sm:mb-0">
              Talk to 1440 -{">"}
            </p>
            <a
              href="https://wa.me/+16203372653?text=Hello"
              className="bg-gray-800 text-white py-3 px-6 font-medium rounded-full flex items-center justify-center mb-4 sm:mb-0"
              aria-label="Contact via WhatsApp"
            >
              <img
                src="/images/whatsapp.png"
                alt="WhatsApp"
                className="h-6 w-6 mr-2.5"
              />
              WhatsApp
            </a>

            <a
              href="https://m.me/100093207430260?text=Hello"
              className="bg-gray-800 text-white py-3 px-6 font-medium rounded-full flex items-center justify-center mb-4 sm:mb-0"
              aria-label="Contact via Messenger"
            >
              <img
                src="/images/messenger.png"
                alt="Messenger"
                className="h-6 w-6 mr-2.5"
              />
              Messenger
            </a>
            <a
              href="sms:+16203372653"
              className="bg-gray-800 text-white py-3 px-6 font-medium rounded-full flex items-center justify-center mb-4 sm:mb-0"
              aria-label="Send SMS"
            >
              <img src="/images/sms.png" alt="SMS" className="h-6 w-6 mr-2.5" />
              SMS
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicLanding;
