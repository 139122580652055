import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import WaitlistSignup from "../UI/WaitlistSignup";
import Mixpanel from "mixpanel-browser";

const Science = ({ userId = null }) => {
  useEffect(() => {
    // Initialize Mixpanel with your project token
    Mixpanel.init("d724d0d0045430023cc58dd17086256b", {
      track_pageview: true,
    });
    Mixpanel.identify(userId);
    Mixpanel.track("Page View", { page: "about" });
  }, []);

  const navigate = useNavigate();
  return (
    <div className="bg-gray-950 text-gray-300 min-h-screen px-8 flex flex-col items-center">
      <div className="container py-12 max-w-2xl">
        <button
          onClick={() => navigate("/")}
          className="flex items-center justify-center text-accent opacity-50 hover:opacity-100 hover:font-bold"
        >
          <img src="/images/submit.png" className="h-5 rotate-180 w-5 mr-2" />
          Back
        </button>
        <div className="flex justify-center items-center gap-16 flex-col">
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            <img
              src="/images/logo-white.png"
              alt="1440 logo"
              className="w-28 brightness-100"
            />
          </div>
          <header className="mb-12">
            <h2 className="text-5xl text-center mb-2 leading-snug opacity-90">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-accent to-darkBlue">
                The Science of 1440
              </span>
            </h2>
          </header>
        </div>
        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            At 1440, our mission is rooted in the science of time perception and
            purpose. Our team of neuroscientists and AI experts has developed
            technology that redefines how we interact with time. After all, we
            live the lives we schedule.
          </p>
        </section>

        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            We can help you plan and execute a life of purpose, joy, and
            accomplishment.
          </p>
        </section>
        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            Our protocol is powered by insights from behavior change,
            chronobiology, time perception, grit, science-based coaching, and
            more. We combine the efforts of experienced and credentialed coaches
            alongside the best technology can deliver to bring you something
            that is less than a tenth the price of traditional coaching, all
            while being even more effective.
          </p>
        </section>
        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            By working with 1440, you’re embracing a philosophy that each of the
            day’s 1,440 minutes is a building block your hands. Our technology
            is here to help you provide insights on your time and our human
            coaches provide a listening ear and a personal touch.
          </p>
        </section>
        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            Your new life starts today. This minute.
          </p>
        </section>
        <WaitlistSignup />
        <div className="mt-8">
          <Footer updateTheme={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default Science;
