import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { PhoneIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Cookies from "js-cookie";
import { OtpInput } from "reactjs-otp-input";

const SyncPhone = ({ onSync, onCloseSync, starterPhone }) => {
  const [phoneNumber, setPhoneNumber] = useState(starterPhone);
  const [otp, setOtp] = useState("");
  const [stage, setStage] = useState(1); // 1 for phone input, 2 for SMS verification
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState(30); // Timer state for 30 seconds
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    let interval;
    if (stage === 2 && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [stage, timer]);

  const handlePhoneNumberSubmission = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post("/api/auth/request-otp", {
        phone_number: phoneNumber,
      });
      if (response.status === 200) {
        setStage(2); // Proceed to SMS verification stage
        setTimer(30); // Start 30-second timer
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleOtpVerification = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post("/api/auth/validate-otp", {
        phone_number: phoneNumber,
        otp: otp,
      });

      if (response.status === 200) {
        const { access_token, web_id } = response.data;
        // Save the access token in a cookie
        Cookies.set("1440_access_token", access_token, { expires: 7 });
        Cookies.set("1440_web_id", web_id, { expires: 7 });
        Cookies.set("1440_mobile_id", phoneNumber, { expires: 7 });

        onSync(phoneNumber); // Call onSync after OTP is verified
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleResendOtp = () => {
    setTimer(30);
    handlePhoneNumberSubmission();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (stage === 1 && phoneNumber) {
        handlePhoneNumberSubmission();
      } else if (stage === 2 && otp) {
        handleOtpVerification();
      }
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col md:flex-row items-center justify-center bg-white">
      <div className="relative w-full md:w-1/2 text-center max-w-md md:max-w-lg mx-auto px-4 lg:px-16">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4">
          <img src="/images/logo.png" alt="Logo" className="h-10 md:h-8" />
        </div>
        <div className="border shadow flex justify-center items-center mx-auto mb-4 h-20 w-20 md:h-16 md:w-16 rounded-xl mt-20">
          <PhoneIcon className="h-12 w-12 md:h-8 md:w-8 text-slate-700" />
        </div>

        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          {stage === 1
            ? "Sign in with your phone number"
            : "Verify your phone number"}
        </h1>
        <p className="text-lg md:text-lg mb-6">
          {stage === 1
            ? "Enter your phone number to continue."
            : "Enter the code sent to your phone."}
        </p>
        <div className="flex flex-col justify-center items-center mb-4">
          {stage === 1 ? (
            <PhoneInput
              international
              defaultCountry="US"
              value={phoneNumber}
              onChange={setPhoneNumber}
              onKeyPress={handleKeyPress}
              placeholder="Enter phone number"
              className="form-input w-full px-4 py-3 md:py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-darkBlue focus:border-transparent"
              inputClassName="w-full"
            />
          ) : (
            <div className="flex space-x-2">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                separator={<span></span>}
                inputStyle={{
                  border: "1px solid #d1d5db", // equivalent to border-gray-300
                  borderRadius: "0.375rem", // equivalent to rounded
                  height: "4rem", // equivalent to h-16
                  outline: "none",
                  margin: "0 0.25rem", // equivalent to mx-1
                  fontWeight: "500", // equivalent to font-medium
                  ringWidth: "2", // equivalent to focus:ring-2
                  ringColor: "#1e40af", // equivalent to focus:ring-darkBlue
                  width: "4rem", // custom width
                  textAlign: "center", // custom text alignment
                  // Add any additional styles you want to apply
                }}
                shouldAutoFocus
                isInputNum
                hasErrored={error !== null}
                errorStyle="error"
                onKeyDown={handleKeyPress}
              />
            </div>
          )}
          {stage === 2 && (
            <>
              <p className="text-sm text-gray-500 mt-2">
                {timer > 0
                  ? `Resend OTP in ${timer}s`
                  : "Didn't receive the code?"}
              </p>
              {timer === 0 && (
                <button
                  onClick={handleResendOtp}
                  className="text-blue-600 font-bold mt-2"
                >
                  Resend OTP
                </button>
              )}
            </>
          )}
          <button
            onClick={
              stage === 1 ? handlePhoneNumberSubmission : handleOtpVerification
            }
            className={`bg-darkBlue hover:bg-blue-600 text-white font-bold py-3 md:py-2 px-6 md:px-4 rounded-lg w-full mt-4 flex justify-center items-center ${
              loading ? "cursor-not-allowed" : ""
            }`}
            disabled={loading || (stage === 2 && otp.length < 6)}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                ></path>
              </svg>
            ) : stage === 1 ? (
              "Send code"
            ) : (
              "Verify code"
            )}
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
        <p className="text-gray-400 text-xs">
          We respect your privacy. Your number will only be used for
          authentication and coaching purposes.
        </p>
      </div>
      <div className="hidden md:flex md:w-1/2 lg:w-3/5 xl:w-3/4 h-screen items-center bg-gray-300 rounded-3xl">
        <img
          src="https://1440.ai/images/coach-cover.png"
          alt="Placeholder"
          className="object-cover w-full ml-12"
          style={{ height: "90vh", objectPosition: "left center" }}
        />
      </div>
    </div>
  );
};

export default SyncPhone;
