import React from "react";
import MessageList from "./MessageList";
import TypingIndicator from "../../chat/TypingIndicator";
import InputField from "./InputField";
import SuggestedMessages from "../../chat/SuggestedMessages";
import axios from "axios";
import { CoachContext } from "../../../CoachContext";

const UserLogs = ({ conversation, user_id, update_conversation }) => {
  const { coachId } = React.useContext(CoachContext);
  const [userInput, setUserInput] = React.useState("");
  const sendMessage = async (message) => {
    setUserInput(message);

    // Assuming coach_id and user_id are available in the component's scope
    const data = {
      body: message,
      coach_id: coachId,
      user_id: user_id,
    };

    try {
      const response = await axios.post("/api/coach/message", data);
      update_conversation({ role: "Noah", content: message });
    } catch (error) {
      console.error(error);
    }
  };

  const formattedConversation = conversation?.map((message) => {
    let role = message.role;
    let content = message.content;

    if (Array.isArray(role)) {
      role = role[1] === "agent" ? "assistant" : "user";
    }

    if (Array.isArray(content)) {
      content = content[1];
    }

    return { role, content };
  });

  console.log(formattedConversation);

  return (
    <div className="overflow-auto mx-auto mt-5" style={{ maxHeight: "36rem" }}>
      <MessageList conversation={formattedConversation} />
      <InputField
        sendMessage={sendMessage}
        userInput={userInput}
        setUserInput={setUserInput}
      />
    </div>
  );
};

export default UserLogs;
