import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { CoachContext } from "../../../CoachContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import User from "./User";

const Users = () => {
  const { coachId } = React.useContext(CoachContext);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [sortOption, setSortOption] = useState("Most Recent");

  useEffect(() => {
    if (coachId) {
      setIsLoading(true);
      axios
        .get(`/api/coach/users/${coachId}/basic`)
        .then((response) => {
          if (response.data.status === "success") {
            setUsers(response.data.users);
            console.log(response.data.users);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [coachId]);

  const sortUsers = (users, option) => {
    if (!users) return [];
    switch (option) {
      case "Most Recent":
        return [...users].sort(
          (a, b) =>
            new Date(b.log[b.log.length - 1].time) -
            new Date(a.log[a.log.length - 1].time)
        );
      case "Oldest":
        return [...users].sort(
          (a, b) =>
            new Date(a.log[a.log.length - 1].time) -
            new Date(b.log[b.log.length - 1].time)
        );
      default:
        return users;
    }
  };

  const handleRowClick = (user) => {
    setSelectedUser(user.web_id);
    console.log(user.web_id);
  };

  if (selectedUser) {
    return (
      <User
        userId={selectedUser}
        onClose={() => setSelectedUser(null)}
        onCloseTitle="All users"
      />
    );
  }

  const sortOptions = (
    <div className="text-right">
      <label className="inline-flex items-center text-sm font-medium text-gray-700">
        Sort by
        <select
          className="ml-4 border-none bg-slate-200 text-base px-4 py-2.5 rounded-lg focus:ring-0"
          value={sortOption} // Set the value to the current sort option
          onChange={(e) => setSortOption(e.target.value)} // Update the sort option on change
        >
          <option className="px-3 mx-3">Most Recent</option>
          <option>Oldest</option>
        </select>
      </label>
    </div>
  );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan="6" className="text-center py-10">
            Loading users...
          </td>
        </tr>
      );
    } else if (users && users.length > 0) {
      return sortUsers(users, sortOption).map((user) => (
        <tr
          className="hover:bg-blue-50 cursor-pointer"
          onClick={() => handleRowClick(user)}
          key={user.web_id}
        >
          <td className="px-6 py-4 whitespace-nowrap text-base text-slate-500">
            {user.memory.name ? user.memory.name : user.web_id}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-slate-500">
            {
              user.log.filter(
                (log) =>
                  new Date(log.time) >
                  new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
              ).length
            }
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-slate-500">
            {Math.floor(
              (Math.random() / 3) *
                user.log.filter(
                  (log) =>
                    new Date(log.time) >
                    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
                ).length
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-slate-500">
            {Math.floor(
              (Math.random() / 3) *
                user.log.filter(
                  (log) =>
                    new Date(log.time) >
                    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
                ).length
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-slate-500">
            {formatLastActive(user.log[user.log.length - 1].time)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-base text-slate-500">
            {user.next_appointment}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="6" className="text-center py-10">
            No users found.
          </td>
        </tr>
      );
    }
  };

  return (
    <Layout
      title="All Users"
      rightElement={sortOptions}
      background="bg-slate-100"
    >
      <div className="flex bg-slate-100 flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-slate-200">
              <table className="min-w-full divide-y divide-slate-200">
                <thead className="">
                  <tr>
                    <th className="px-6 py-3 text-left text-base  font-bold text-slate-700 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                      Messages
                      <div className="text-xs lowercase text-slate-500">
                        (this week)
                      </div>
                    </th>
                    <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                      Assessments
                      <div className="text-xs lowercase text-slate-500">
                        (this week)
                      </div>
                    </th>
                    <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                      Intervention
                      <div className="text-xs lowercase text-slate-500">
                        (this week)
                      </div>
                    </th>
                    <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                      Last
                    </th>
                    <th className="px-6 py-3 text-base text-right font-bold text-slate-700 uppercase tracking-wider">
                      Next Appointment
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200 text-black">
                  {renderTableBody()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function formatLastActive(lastUpdated) {
  const last = new Date(lastUpdated);
  if (isNaN(last.getTime())) return "0 days ago"; // Check if the date is valid
  const now = new Date();
  const diff = now - last;
  const diffMinutes = Math.floor(diff / 60000);
  if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
  const diffHours = Math.floor(diff / 3600000);
  if (diffHours < 24) return `${diffHours} hours ago`;
  const diffDays = Math.floor(diff / 86400000);
  return `${diffDays} days ago`;
}

export default Users;
