import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CoachContext } from "../../../CoachContext";
import EditProtocol from "./EditProtocol";
import Layout from "../Layout";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import AddProtocol from "./AddProtocol";

const Protocol = () => {
  const [protocols, setProtocols] = useState([]);
  const [selectedProtocol, setSelectedProtocol] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [protocolToDelete, setProtocolToDelete] = useState(null);
  const { coachId } = useContext(CoachContext);
  const [loading, setLoading] = useState(true);
  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  useEffect(() => {
    axiosWithAuth()
      .get(`/protocols/${coachId}`)
      .then((response) => {
        setProtocols(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setProtocols([]);
        setLoading(false);
      });
  }, [coachId]);

  const handleDeleteConfirmation = (protocolId) => {
    setShowDeleteModal(true);
    setProtocolToDelete(protocolId);
  };

  const handleDelete = () => {
    axiosWithAuth()
      .delete(`/protocols/${protocolToDelete}`)
      .then(() => {
        setProtocols(
          protocols.filter((protocol) => protocol._id !== protocolToDelete)
        );
        setShowDeleteModal(false);
      })
      .catch((error) => console.error(error));
  };

  const handleEdit = (protocolId) => {
    setSelectedProtocol(protocolId);
    setIsEditing(true);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
  };

  const handleProtocolAdded = (newProtocolData) => {
    setProtocols([...protocols, newProtocolData]);
  };

  const updateProtocol = (updatedProtocol) => {
    setProtocols(
      protocols.map((protocol) =>
        protocol._id === selectedProtocol ? updatedProtocol : protocol
      )
    );
  };

  if (isEditing) {
    return (
      <EditProtocol
        protocol={protocols.find(
          (protocol) => protocol._id === selectedProtocol
        )}
        onClose={handleCloseEdit}
        updateProtocol={updateProtocol}
      />
    );
  }
  return (
    <Layout title="Protocol">
      <div className="">
        <h3 className="text-lg font-medium mb-1 text-slate-700">
          All protocols
        </h3>
        <p className="text-slate-400 text-sm w-2/3">
          Create and modify different user journeys through 1440 for your
          clients. The protocol allows you to define and customize a series of
          conversations that 1440 will prioritize bringing up.
        </p>
        {loading ? (
          <div className="font-medium text-lg">Loading protocols...</div>
        ) : (
          <div className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {protocols.length > 0 ? (
              protocols.map((protocol) => (
                <div
                  key={protocol._id}
                  className="bg-white overflow-hidden shadow rounded-lg"
                >
                  <div className="">
                    <div className="flex items-center justify-between p-4 bg-slate-50">
                      <h3 className="text-lg leading-6 font-medium text-gray-900 mb-0">
                        {protocol?.title}
                      </h3>
                      <div className="ml-2 flex-shrink-0 flex">
                        <button
                          onClick={() => handleEdit(protocol._id)}
                          className="bg-slate-200 p-2 rounded-md text-slate-500 hover:bg-slate-300"
                        >
                          <PencilSquareIcon className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteConfirmation(protocol._id)}
                          className="ml-3 bg-slate-200 p-2 rounded-md text-slate-500 hover:bg-slate-300"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="bg-yellow-100 text-yellow-800 p-4 rounded-lg text-left mb-0">
                <p className="mb-0">No protocols added.</p>
              </div>
            )}
          </div>
        )}
        {showDeleteModal && (
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        Delete Protocol
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this protocol? This
                          action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <AddProtocol onProtocolAdded={handleProtocolAdded} />
      </div>
    </Layout>
  );
};
export default Protocol;
