import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../Layout";
import ProtocolOutline from "./ProtocolOutline";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import EditProtocolField from "./EditProtocolField";
import EditTask from "./EditTask";

import { debounce } from "lodash";
const EditProtocol = ({
  protocol,
  onClose,
  updateProtocol,
  isUserProtocol = "",
}) => {
  const [protocolData, setProtocolData] = useState(protocol ? protocol : {});
  const handleTaskUpdate = (updatedTask) => {
    const updatedTasks = protocolData?.tasks?.map((task) =>
      task.task_id === updatedTask.task_id ? updatedTask : task
    );
    handleProtocolChange("tasks", updatedTasks);
  };

  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleAddTask = () => {
    const newTask = {
      description: "",
      execute_at: new Date().toISOString(),
      type: "one-time",
      task_id: Date.now().toString(), // Simple unique ID generator for example purposes
    };
    handleProtocolChange("tasks", [...protocolData.tasks, newTask]);
  };

  // Function to remove a task by task_id
  const handleRemoveTask = (taskId) => {
    const updatedTasks = protocolData?.tasks?.filter(
      (task) => task.task_id !== taskId
    );
    handleProtocolChange("tasks", updatedTasks);
  };
  // This function now handles all updates to the protocol
  const handleProtocolChange = (key, value) => {
    const updatedProtocol = { ...protocolData, [key]: value };
    setProtocolData(updatedProtocol); // Update local state
    if (isUserProtocol) {
      debounceUpdateUserProtocol(updatedProtocol);
    } else {
      // Debounce the update call to prevent excessive requests
      debounceUpdateProtocol(updatedProtocol);
    }
  };

  // Debounced function to update the protocol on the server
  const debounceUpdateProtocol = debounce((updatedProtocol) => {
    axiosWithAuth()
      .post("/protocol/update", updatedProtocol)
      .then((response) => {
        if (response.status === 200) {
          console.log("Update successful:", response.data);
          updateProtocol(updatedProtocol); // This updates the protocol in the parent component's state
        }
      })
      .catch((error) => {
        console.error("Error updating protocol:", error);
      });
  }, 500);

  const debounceUpdateUserProtocol = debounce((updatedProtocol) => {
    console.log("lets go");
    console.log(updatedProtocol);
    console.log(isUserProtocol);
    axiosWithAuth()
      .post("/user_protocol/update/", {
        protocol_data: updatedProtocol,
        user_id: isUserProtocol,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Update successful:", response.data);
          updateProtocol(updatedProtocol); // This updates the protocol in the parent component's state
        }
      })
      .catch((error) => {
        console.error("Error updating protocol:", error);
      });
  }, 500);
  const fields = [
    {
      key: "title",
      title: "Title",
      description: "Enter the title for the protocol.",
      placeholder: "Enter title here...",
      value: protocol?.title || "",
    },
    {
      key: "personality",
      title: "Personality",
      description: "Customize the personality for the conversation.",
      placeholder: "Describe the personality...",
      value: protocol?.personality || "",
    },
    {
      key: "topic_writing_guide",
      title: "Topic Writing Guide",
      description:
        "At every conersational shift of topic, we generate a guide based on context, and the new topic, about how the coach should approach this new topic. This is the prompt used to generate that guide.",
      placeholder: "How should the coach address each topic?",
      value: protocol?.topic_writing_guide || "",
    },
    {
      key: "current_module",
      title: isUserProtocol
        ? "Current Conversation Topic"
        : "First Conversation Topic",
      description: isUserProtocol
        ? "Title of the current conversation topic."
        : "Title of the first conversation topic.",
      placeholder: isUserProtocol
        ? "Enter the current topic title..."
        : "Enter the first topic title...",
      value: protocol?.current_module || "",
    },
    {
      key: "current_prompt",
      title: "Initial Prompt",
      description: isUserProtocol
        ? "Define the initial prompt for the current conversation."
        : "Define the initial prompt for the first conversation.",
      placeholder: isUserProtocol
        ? "What will the coach say now?"
        : "What will the coach say first?",
      value: protocol?.current_prompt || "",
    },
  ];

  if (isUserProtocol) {
    return (
      <div>
        <h3 className="text-lg mt-4 font-medium mb-1 text-slate-700">
          User's protocol outline
        </h3>
        <p className="text-slate-400 text-sm w-2/3">
          The underlying protocol, that will guide the AI in what conversations
          to have, at what stages. This is not completely linear, but an outline
          that will inform the types of things to bring up next.
        </p>
        <ProtocolOutline
          initialItems={protocolData?.summary || []}
          onUpdate={handleProtocolChange}
        />
        <div className="mt-12">
          <h1 className="text-xl font-medium text-slate-700 mb-6">
            Edit user's protocol fields
          </h1>
          {fields.map((field) => (
            <EditProtocolField
              key={field.key}
              title={field?.title}
              description={field.description}
              placeholder={field.placeholder}
              value={protocolData[field.key] || ""}
              onChange={(e) => handleProtocolChange(field.key, e.target.value)}
            />
          ))}
        </div>
        <div className="mt-12">
          <h1 className="text-lg font-medium text-slate-700 mb-2">
            Edit reminders
          </h1>
          <p className="text-slate-400 text-sm w-2/3">
            If the user's phone number is synced, 1440 will proactively send
            messages based on the description and conversational context.
          </p>
          {protocolData?.tasks &&
            protocolData?.tasks?.map((task) => (
              <div key={task.task_id} className="mb-4">
                <EditTask
                  task={task}
                  onUpdate={handleTaskUpdate}
                  handleRemove={handleRemoveTask}
                  isUserTask={true}
                />
              </div>
            ))}
          <button
            onClick={handleAddTask}
            className="mt-4 bg-slate-200 text-slate-500 py-2 px-4 rounded hover:bg-slate-300 mb-8"
          >
            + Add reminder
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <Layout
        title={protocol?.title}
        rightElement={
          <button
            onClick={onClose}
            className="flex items-center bg-slate-200 py-2 px-3 rounded-lg text-slate-600"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            All protocols
          </button>
        }
      >
        <div>
          <h3 className="text-lg font-medium mb-1 text-slate-700">
            Protocol outline
          </h3>
          <p className="text-slate-400 text-sm w-2/3">
            The underlying protocol, that will guide the AI in what
            conversations to have, at what stages. This is not completely
            linear, but an outline that will inform the types of things to bring
            up next.
          </p>
          <ProtocolOutline
            initialItems={protocolData?.summary || []}
            onUpdate={handleProtocolChange}
          />
          <div className="mt-12">
            <h1 className="text-xl font-medium text-slate-700 mb-6">
              Edit protocol fields
            </h1>
            {fields.map((field) => (
              <EditProtocolField
                key={field.key}
                title={field?.title}
                description={field.description}
                placeholder={field.placeholder}
                value={protocolData[field.key] || ""}
                onChange={(e) =>
                  handleProtocolChange(field.key, e.target.value)
                }
              />
            ))}
          </div>
          <div className="mt-12">
            <h1 className="text-lg font-medium text-slate-700 mb-2">
              Edit reminders
            </h1>
            <p className="text-slate-400 text-sm w-2/3">
              If the user's phone number is synced, 1440 will proactively send
              messages based on the description and conversational context.
            </p>
            {protocolData?.tasks &&
              protocolData?.tasks?.map((task) => (
                <div key={task.task_id} className="mb-4">
                  <EditTask
                    task={task}
                    onUpdate={handleTaskUpdate}
                    handleRemove={handleRemoveTask}
                  />
                </div>
              ))}
            <button
              onClick={handleAddTask}
              className="mt-4 bg-slate-200 text-slate-500 py-2 px-4 rounded hover:bg-slate-300 mb-8"
            >
              + Add reminder
            </button>
          </div>
        </div>
      </Layout>
    );
  }
};

export default EditProtocol;
