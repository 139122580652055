import React from "react";
import "./Question.css"; // Import only for the animation

const Question = ({ question, onResponse, selectedOption }) => {
  const handleSelection = (option) => {
    onResponse(option);
  };

  return (
    <div className="">
      <div className="flex flex-col items-center py-10 lg:py-28 lg:px-32 space-y-4 lg:space-y-8 2xl:space-y-12">
        <h2 className="text-lg font-medium mb-4 text-center lg:text-2xl 2xl:text-3xl opacity-70">
          {question.text}
        </h2>
        <div className="grid grid-cols-7 gap-2 px-8 md:px-0 w-full items-center lg:gap-6 2xl:gap-8">
          <span className="text-xs text-gray-600 opacity-70 text-right lg:mr-10 font-medium dark:text-gray-400 lg:text-lg 2xl:text-xl">
            Agree
          </span>
          {[1, 2, 3, 4, 5].map((option, index) => (
            <label
              key={index}
              className="flex justify-center items-center relative"
            >
              <input
                type="radio"
                id={`option-${index}`}
                name="response"
                value={option}
                className="hidden"
                onChange={() => handleSelection(option)}
                checked={selectedOption === option}
              />
              <div
                className={`h-8 w-8 rounded-full cursor-pointer lg:h-16 lg:w-16 2xl:h-24 2xl:w-24 border-2 ${
                  selectedOption === option
                    ? "selected bg-accent border-accent"
                    : "border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                }`}
                onClick={() => handleSelection(option)}
              >
                {selectedOption === option ? (
                  <img
                    src="/images/checked.png"
                    alt="Selected"
                    className="checkmark w-1/4"
                  />
                ) : (
                  ""
                )}
              </div>
            </label>
          ))}
          <span className="text-xs lg:ml-10 text-gray-600 opacity-70 font-medium dark:text-gray-400 lg:text-lg 2xl:text-xl">
            Disagree
          </span>
        </div>
      </div>
      <hr className="w-full bg-gray-300 dark:opacity-20 dark:bg-gray-900"></hr>
    </div>
  );
};

export default Question;
