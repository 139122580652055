import React, { useState, useRef, useEffect } from "react";
// import VoiceRecorder from "./VoiceRecorder";

const InputField = ({ sendMessage, highlightInput }) => {
  const [hover, setHover] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [isTalking, setIsTalking] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(userInput.trim());
      setUserInput("");
    }
  };
  const handleInput = (e) => {
    setUserInput(e.target.value);
    setIsTalking(e.target.value.length > 0);

    // Dynamically adjust the height of the textarea to fit content
    const target = e.target;
    target.style.height = "inherit"; // Reset height to recalculate
    target.style.height = `${target.scrollHeight}px`; // Set new height based on scroll height

    // Adjust padding-right dynamically
    adjustTextareaPadding(target);

    // Scroll the textarea into view
    setTimeout(() => {
      target.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, 0);
  };

  const adjustTextareaPadding = (textarea) => {
    const paddingForButtons = "100px"; // Adjust based on the size of your button area
    const defaultPadding = "1rem"; // Adjust based on your default styling

    // Approximate check if we're likely on the last line
    if (textarea.scrollHeight - textarea.scrollTop <= textarea.clientHeight) {
      textarea.style.paddingRight = paddingForButtons; // Add extra padding to avoid text under buttons
    } else {
      textarea.style.paddingRight = defaultPadding; // Default padding when not on the last line
    }
  };

  const handleSubmit = () => {
    if (userInput.trim()) {
      sendMessage(userInput.trim());
      setUserInput("");
      setIsTalking(false);
    } else {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={`relative flex items-end w-full border-2 border-slate-200 rounded-full py-1 px-2 ${
        isTalking ? "border-accent" : "border-gray-200"
      }`}
      style={{ borderRadius: "25px" }}
    >
      <textarea
        ref={inputRef}
        value={userInput}
        onChange={handleInput}
        onKeyPress={handleKeyPress}
        className="resize-none w-full bg-transparent dark:bg-transparent opacity-60 focus:outline-none cursor-text font-normal tracking-wide text-sm dark:text-white text-black py-2 px-1.5"
        placeholder="Message..."
        style={{
          minHeight: "30px",
          overflow: "hidden",
          border: "none",
          outline: "none",
          boxShadow: "none",
        }}
        autoFocus={highlightInput}
        rows="1" // Start with a single line
      />
      <div className="absolute right-2 bottom-2 flex items-center space-x-2">
        <button
          onClick={() => {
            window.location.href = `tel:+16203372653`;
          }}
          className=" opacity-80 bg-transparent hover:bg-white text-white dark:text-white rounded-full shadow-sm text-xs"
        ></button>
        {/* <VoiceRecorder
          onVoiceTranscript={(transcript) => {
            sendMessage(transcript);
            setIsTalking(true);
          }}
        /> */}
        <button
          onClick={handleSubmit}
          className={`${
            userInput.trim().length > 0
              ? "bg-accent text-black"
              : "bg-transparent text-gray-500"
          } font-semibold p-2 border ${
            userInput.trim().length > 0 ? "border-slate-50" : "border-slate-50"
          } rounded-full shadow-sm text-xs`}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <img
            src="/images/up-arrow.png"
            alt="Submit"
            className={
              userInput.trim().length > 0
                ? "w-3 brightness-100"
                : "w-3 brightness-100"
            }
          />
        </button>
      </div>
    </div>
  );
};

export default InputField;
