import React, { useEffect, useRef, useState } from "react";
import Mixpanel from "mixpanel-browser";

function VideoComponent({ userId, onSkip }) {
  const playerRef = useRef(null);
  const [watchedTime, setWatchedTime] = useState(0);

  // Initialize Mixpanel and track page view
  useEffect(() => {
    Mixpanel.init("d724d0d0045430023cc58dd17086256b");
    Mixpanel.identify(userId);
    Mixpanel.track("Page View", { page: "Demo Video" });
  }, [userId]);

  // Setup Vimeo player and tracking
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    script.onload = () => {
      if (window.Vimeo) {
        playerRef.current = new window.Vimeo.Player("vimeo-player", {
          autoplay: true, // This will make the video autoplay
        });
        playerRef.current.on("ended", () => {
          Mixpanel.identify(userId);
          console.log("Skipped", watchedTime);
          Mixpanel.track("Skipped Video", { watchedTime });
          onSkip();
        });
        playerRef.current.on("timeupdate", function (data) {
          setWatchedTime(data.seconds);
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      if (playerRef.current) {
        playerRef.current.off("ended");
        playerRef.current.off("timeupdate");
        playerRef.current.destroy();
      }
      document.body.removeChild(script);
    };
  }, [onSkip]);

  useEffect(() => {
    setTimeout(() => {
      try {
        const existingScript = document.getElementById("celli-script");
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
        const celli = document.getElementById("chat-container");
        console.log("🚀 ~ file: DemoVideo.js:11 ~ useEffect ~ celli:", celli);
        if (celli) {
          document.body.removeChild(celli);
        }
      } catch (error) {
        console.log("🚀 ~ file: DemoVideo.js:17 ~ return ~ error:", error);
      }
    }, 500);
  }, [onSkip]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ position: "relative", width: "75%" }}>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/895330870?h=e7738edd50&autoplay=1&byline=0"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            id="vimeo-player"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="1440_V17_5"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <button
          onClick={() => onSkip(watchedTime)}
          style={{
            position: "absolute",
            top: "-30px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#FFF",
            color: "#000",
            padding: "10px 20px",
            borderRadius: "20px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          }}
          className="font-medium text-xs md:text-lg"
        >
          <img
            className="brightness-0"
            src="/images/skip.png"
            style={{ marginRight: "10px", height: "20px" }}
          />
          Skip to the coach
        </button>
      </div>
    </div>
  );
}

export default VideoComponent;
