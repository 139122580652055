import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import AspectRatioCarousel from "./AspectRatioCarousel";
import Mixpanel from "mixpanel-browser";

const ResolutionsShowcase = ({ resolutions, onClose }) => {
  Mixpanel.init("d724d0d0045430023cc58dd17086256b", { track_pageview: true });
  const [currentItem, setCurrentItem] = useState(0);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleWaitlistSignup = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch("https://api.1440.ai/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      alert(data.message || "You have been added to the waitlist!");
    } catch (error) {
      alert("Failed to join the waitlist. Please try again.");
    }
    setIsSubmitting(false);
  };

  const downloadCard = (cardId) => {
    const cardElement = document.getElementById(cardId);
    if (cardElement) {
      try {
        const image = cardElement.src;
        const anchor = document.createElement("a");
        anchor.setAttribute("href", image);
        anchor.setAttribute("download", `${cardId}.png`);
        anchor.click();
        Mixpanel.track("Card Downloaded", { cardId: cardId });
      } catch (error) {
        console.error("Error downloading card:", error);
      }
    }
  };

  return (
    <div className="relative lg:absolute inset-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center">
      <div className="bg-gray-900 rounded-lg p-4 lg:p-10 space-y-4 lg:space-x-8 overflow-hidden shadow-xl transform transition-all my-8 mx-4 flex flex-col lg:flex-row justify-center md:max-w-6xl md:w-full">
        <div className="w-full lg:w-2/5">
          <AspectRatioCarousel
            resolutions={resolutions}
            setCurrentItem={setCurrentItem}
          />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col">
          <div>
            <h2 className="text-xl lg:text-2xl md:text-3xl font-normal text-white text-center lg:text-left">
              Share to have{" "}
              <strong className="text-accent">more than 300%</strong> higher
              chance of reaching your goal
            </h2>
            <button
              onClick={() => downloadCard(`resolutionCard-${currentItem}`)}
              className="flex items-center font-semibold w-full justify-center text-white px-4 py-2 lg:py-4 mb-4 bg-accent rounded text-sm"
            >
              <img src="/images/download.png" className="h-4 lg:h-6 mr-2" />
              DOWNLOAD CURRENT CARD
            </button>
            <p className="text-gray-300 text-base lg:text-lg md:text-xl mt-2 text-center lg:text-left">
              Committing to your goals is the biggest step you can take right
              now towards achieving them. Share your New Year's resolution on
              socials to make sure you'll achieve your goals!
            </p>
          </div>
          <div className="border-2 border-white p-4 lg:p-6">
            <img
              src="/images/logo-white.png"
              alt="1440 logo"
              className="w-20 lg:w-28 my-4 lg:my-5 mx-auto lg:-ml-1 brightness-0 dark:brightness-100"
            />
            <p className="text-white text-base lg:text-lg font-light mt-4 lg:mt-6 mb-1 text-center lg:text-left">
              Want to be kept accountable for your resolution?
            </p>
            <p className="text-white text-xl lg:text-2xl md:text-3xl font-light mb-4 lg:mb-6 text-center lg:text-left">
              Sign up...
            </p>
            <form onSubmit={handleWaitlistSignup} className="mb-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="bg-transparent border-b-2 border-white p-2 pl-0 mb-4 lg:mb-6 w-full text-white placeholder-gray-500 focus:outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isSubmitting}
              />
              <button
                type="submit"
                className="w-full bg-accent text-white py-2 lg:py-4 uppercase tracking-wider hover:bg-orange-700 transition duration-300"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Join waitlist"}
              </button>
              <p className="text-white text-sm lg:text-md font-light mt-3 lg:mt-4 mb-0 text-center lg:text-left">
                1440's alpha version is free with an invite code
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResolutionsShowcase;
