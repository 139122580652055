import React, { useState } from "react";

const TextingConsent = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isTextingStarted, setIsTextingStarted] = useState(false);

  const handleStartTexting = async () => {
    setIsTextingStarted(true);
    // Simulate a delay to mimic the texting process
    setTimeout(() => {
      console.log(`Starting texting with phone number: ${phoneNumber}`);
      alert("Texting started successfully!");
      setIsTextingStarted(false);
    }, 2000); // 2 seconds delay
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center bg-gradient-to-r from-accent to-lightBlue">
      <div className="bg-white rounded-lg shadow-xl p-8 text-left w-full md:w-2/3">
        <h2 className="text-4xl font-bold text-left mb-6">Texting Consent</h2>
        <p className="text-gray-700 text-lg mb-6">
          By engaging in text-based interactions with the 1440 texting service,
          you acknowledge and agree to the following terms: Standard message and
          data rates may apply according to your service plan. The frequency of
          messages during interaction with our service may vary. You reserve the
          right to discontinue text communications at any point. For detailed
          information regarding the use, collection, and protection of your
          data, please refer to our{" "}
          <a
            href="/privacy-policy"
            className="text-blue-600 hover:text-blue-800"
          >
            Privacy Policy
          </a>
          .
        </p>
        <div className="mt-6">
          <input
            type="text"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="p-2 border border-gray-300 rounded-md w-full md:w-1/2 mr-2"
          />
          <button
            onClick={handleStartTexting}
            disabled={isTextingStarted}
            className={`mt-4 ${
              isTextingStarted
                ? "bg-gray-500"
                : "bg-lightBlue hover:bg-orange-700"
            } text-white font-bold py-2 px-4 rounded`}
          >
            {isTextingStarted ? "Please wait..." : "Text me"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TextingConsent;
