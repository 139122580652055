import React, { useState } from "react";

const CoachDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentCoach, setCurrentCoach] = useState({
    name: "Coach Nia",
    title: "Purpose Coach",
    image: "/images/ava.png",
  });

  const coaches = [
    { name: "Coach Nia", title: "Purpose Coach", image: "/images/ava.png" },
    { name: "Coach Alex", title: "Executive Coach", image: "/images/ava.png" },
    { name: "Coach Mia", title: "Life Coach", image: "/images/ava.png" },
  ];

  const handleCoachChange = (coach) => {
    setCurrentCoach(coach);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex hover:bg-gray-100 dark:hover:bg-gray-700 items-center bg-transparent text-black dark:text-white h-10 px-3 border border-gray-500 rounded transition duration-300"
      >
        <img
          src={currentCoach.image}
          alt={currentCoach.name}
          className="h-5 w-5 rounded-full mr-2"
        />
        <span className="text-sm">{currentCoach.name}</span>
      </button>

      {isOpen && (
        <ul className="absolute z-10 pt-3 bg-white dark:bg-dark border border-gray-300 px-0 rounded mt-2 w-56 overflow-auto transition duration-300">
          {coaches.map((coach, index) => (
            <li
              key={index}
              className="flex items-center px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition duration-300"
              onClick={() => handleCoachChange(coach)}
            >
              <img
                src={coach.image}
                alt={coach.name}
                className="h-6 w-6 rounded-full mr-2"
              />
              <div>
                <p className="m-0 text-xs">{coach.name}</p>
                <p className="m-0 text-xs opacity-60">{coach.title}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CoachDropdown;
