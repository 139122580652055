import React, { useState } from "react";
import Screen1 from "./GroundScreen1";
import Screen2 from "./GroundScreen2";
import Screen3 from "./GroundScreen3";
import Screen4 from "./GroundScreen4";
import Screen5 from "./GroundScreen5";
import Screen6 from "./GroundScreen6";
import Screen7 from "./GroundScreen7";
import Screen8 from "./GroundScreen8";
import Screen9 from "./GroundScreen9";
import Screen10 from "./GroundScreen10";
import Screen11 from "./GroundScreen11";
import Screen12 from "./GroundScreen12";
import Screen13 from "./GroundScreen13";
import Screen14 from "./GroundScreen14";
import Screen15 from "./GroundScreen15";
import Screen16 from "./GroundScreen16";
import Screen17 from "./GroundScreen17";
import Screen18 from "./GroundScreen18";
import Screen19 from "./GroundScreen19";
import Mixpanel from "mixpanel-browser";

const GroundFlow = () => {
  Mixpanel.init("d724d0d0045430023cc58dd17086256b", { track_pageview: true });
  const [currentStep, setCurrentStep] = useState(0);
  const [results, setResults] = useState({});

  const handleUpdate = (data) => {
    // Track the update event with Mixpanel
    Mixpanel.track("Screen Update", {
      step: `screen${currentStep + 1}`,
      data: data,
    });

    setResults((prevResults) => ({
      ...prevResults,
      [`screen${currentStep + 1}`]: data,
    }));
  };

  const handleContinue = () => {
    // Track the continue event with Mixpanel
    Mixpanel.track("Continue Clicked", {
      step: `screen${currentStep + 1}`,
      isLastStep: isLastStep,
    });

    if (!isLastStep) {
      setCurrentStep(currentStep + 1);
    } else {
      // Track the final submit event with Mixpanel
      Mixpanel.track("Final Submit", {
        results: results,
      });
    }
  };

  const screens = [
    <Screen1 key="screen1" onUpdate={handleUpdate} />,
    <Screen2 key="screen2" onUpdate={handleUpdate} />,
    <Screen3 key="screen3" onUpdate={handleUpdate} />,
    <Screen4
      key="screen4"
      userPrimaryGoal={results["screen2"]}
      userActivityLevel={results["screen3"]}
    />,
    <Screen5 key="screen5" onUpdate={handleUpdate} />,
    <Screen6 key="screen6" onUpdate={handleUpdate} />,
    <Screen7 key="screen7" onUpdate={handleUpdate} />,
    <Screen8 key="screen8" onUpdate={handleUpdate} />,
    <Screen9 key="screen9" onUpdate={handleUpdate} />,
    <Screen10 key="screen10" onUpdate={handleUpdate} />,
    <Screen11 key="screen11" onUpdate={handleUpdate} />,
    <Screen12 key="screen12" onUpdate={handleUpdate} />,
    <Screen13 key="screen13" onUpdate={handleUpdate} />,
    <Screen14 key="screen14" onUpdate={handleUpdate} />,
    <Screen15 key="screen15" onUpdate={handleUpdate} />,
    <Screen16 key="screen16" onUpdate={handleUpdate} />,
    <Screen17 key="screen17" onUpdate={handleUpdate} />,
    <Screen18 key="screen18" onUpdate={handleUpdate} />,
    <Screen19 key="screen19" onUpdate={handleUpdate} />,
  ];

  const isLastStep = currentStep === screens.length - 1;

  return (
    <div
      className={
        "flex flex-col h-screen bg-black" + (isLastStep ? " h-full" : "")
      }
    >
      <div className="pt-2 pb-4 flex justify-center sticky top-0 z-10 bg-black">
        <img
          src="/images/logo-white.png"
          alt="Logo"
          className="h-7 mt-2 w-auto"
        />
      </div>
      <div className="w-full sticky top-8 z-10">
        <div className="w-full bg-gray-800">
          <div
            className="bg-accent h-2 rounded-full transition-all duration-300"
            style={{ width: `${(currentStep / (screens.length - 1)) * 100}%` }}
          />
        </div>
      </div>
      <div className="flex-grow flex justify-center overflow-auto mb-32">
        <div
          className={
            "w-full px-4 py-10 " + (isLastStep ? "max-w-full" : "max-w-md")
          }
        >
          {screens[currentStep]}
        </div>
      </div>
      <div
        className={
          "pb-4 bottom-0 w-full  justify-center z-10" +
          (isLastStep ? " hidden" : " fixed flex")
        }
      >
        <div className="w-full max-w-md px-4">
          <div className="max-w-md mx-auto">
            <button
              onClick={handleContinue}
              className={`w-full mb-1 md:md-5 text-black font-bold py-4 border-2 border-accent rounded-full transition-all duration-300 ${
                isLastStep
                  ? "bg-accent hover:bg-accent"
                  : "bg-accent hover:bg-accent-dark"
              }`}
            >
              {isLastStep ? "SELECT COACH" : "CONTINUE"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroundFlow;
