import React from "react";

const Button = ({ children }) => {
  const onClick = () => {
    // redirect to / coaching
    window.location.href = "https://1440.ai/coach";
  };
  return (
    <button
      onClick={onClick}
      className="bg-gradient-to-b from-lightBlue to-darkBlue text-black font-semibold py-3 px-8 rounded-full text-sm"
    >
      {children}
    </button>
  );
};

export default Button;
