import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import UserLogs from "./UserLogs";
import { format } from "date-fns";
import LogCard from "./LogCard";
import Topic from "./Topic";
import { debounce } from "lodash";
import UserProtocolEditor from "./UserProtocolEditor";

import axios from "axios";
const User = ({ userId, onClose, onCloseTitle }) => {
  const [user, setUser] = useState({});
  const [conversation, setConversation] = useState(user.log);
  const name = user?.memory && user?.memory?.name;
  const [humanCoachContext, setHumanCoachContext] = useState("");
  // Add a debounced function to handle updates to the server
  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        console.log("token", localStorage.getItem("coachJwtToken"));
        const response = await axiosWithAuth().get(
          `/api/coach/users/${userId}`
        );
        setUser(response.data);
        setConversation(response.data.log);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
        if (error.response && error.response.status === 401) {
          // Handle unauthorized access (e.g., redirect to login)
        }
      }
    };

    fetchUserDetails();

    const updateLogs = async () => {
      try {
        const response = await axiosWithAuth().get(
          `/api/coach/users/${userId}`
        );
        setConversation(response.data.log);
      } catch (error) {
        console.error("Failed to update logs:", error);
      }
    };

    const intervalId = setInterval(updateLogs, 3000);

    return () => clearInterval(intervalId);
  }, [userId]);

  const debounceUpdateUserContext = debounce(async (userId, context) => {
    try {
      const response = await axiosWithAuth().put(
        `/users/${userId}/context/${context}`
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("Failed to update user context:", error);
    }
  }, 1500); // Increased from 500ms to 1500ms

  const handleHumanCoachContextChange = (value) => {
    setHumanCoachContext(value);
    if (user.web_id) {
      debounceUpdateUserContext(user.web_id, value);
    }
  };

  const handleProtocolUpdate = (updatedProtocol) => {
    // Update the user's protocol in the state and possibly send an update to the server
  };
  let topics = [];
  try {
    topics = user?.topic_agents?.topics.split("\n\n") || [];
  } catch (error) {
    console.error("Failed to parse topics:", error);
  }
  const update_conversation = (message) => {
    setConversation([...conversation, message]);
  };
  const generateUniqueId = (topics) => {
    const maxId = topics.reduce((max, topic) => Math.max(max, topic.id), -1);
    return maxId + 1;
  };

  const [parsedTopics, setParsedTopics] = useState(
    topics.map((topic, index) => {
      const [name, description] = topic.split("\nDescription: ");
      return { id: index, name, note: description || "" }; // Add a unique id for each topic
    })
  );

  const updateTopicsEndpoint = async (userId, topicString) => {
    try {
      const encodedTopicString = encodeURIComponent(topicString);
      const response = await axiosWithAuth().put(
        `/users/${userId}/topics/${encodedTopicString}`
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("Failed to update topics:", error);
    }
  };

  const handleTopicUpdate = async (topicId, updatedTopic) => {
    let newTopics = [...parsedTopics];
    const topicIndex = newTopics.findIndex((topic) => topic.id === topicId);

    if (updatedTopic === null) {
      // Remove the topic by id
      newTopics = newTopics.filter((topic) => topic.id !== topicId);
    } else if (topicIndex > -1) {
      // Update existing topic
      newTopics[topicIndex] = updatedTopic;
    } else {
      // Add new topic
      newTopics.push(updatedTopic);
    }
    // Convert updated topics back to the original string format
    const topicsString = newTopics
      .map((topic) => `${topic.name}\nDescription: ${topic.note}`)
      .join("\n\n");

    // Update local state
    setParsedTopics(newTopics);
    // Call the endpoint with the new topics string
    await updateTopicsEndpoint(user.web_id, topicsString);
  };
  console.log(user.web_id);
  return (
    <Layout
      title={name ? name : user.web_id}
      rightElement={
        <button
          onClick={onClose}
          className="flex items-center bg-slate-200 py-2 px-3 rounded-lg text-slate-600"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          {onCloseTitle ? onCloseTitle : "Back"}
        </button>
      }
    >
      <div className="">
        <h2 className="text-xl mt-4 p-4 bg-slate-200 rounded text-slate-700 inline-block">
          Chat
        </h2>
        <hr></hr>
        <UserLogs
          conversation={conversation ? conversation : []}
          user_id={user.web_id}
          update_conversation={update_conversation}
        />

        {/* Display conversation here */}
        <h2 className="text-xl mt-12 p-4 bg-slate-200 rounded text-slate-700 inline-block">
          See & update info
        </h2>
        <hr></hr>
        <h3 className="text-lg font-medium mt-8 mb-3 text-slate-700">
          Previous conversations
        </h3>
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
          {user?.protocol?.logs &&
            user.protocol.logs
              .slice()
              .reverse()
              .map((log, index) => <LogCard key={index} log={log} />)}
        </div>

        <h3 className="text-lg font-medium mt-8 mb-0 text-slate-700">
          Give context to 1440
        </h3>
        <div className="mt-0 mb-0">
          <input
            id="humanCoachContext"
            type="text"
            value={humanCoachContext}
            onChange={(e) => handleHumanCoachContextChange(e.target.value)}
            className="border border-slate-300 rounded py-2 px-4 w-full mt-2"
            placeholder="Context about this user and information about how to approach future conversations..."
          />
        </div>
        <h3 className="text-lg font-medium mt-8 mb-3 text-slate-700">
          Upcoming topics
        </h3>
        <div className="grid grid-flow-row auto-rows-max md:grid-cols-2 2xl:grid-cols-3 gap-4">
          {parsedTopics.map((topic) => (
            <Topic
              key={topic.id} // Use unique id for key
              topic={topic}
              onUpdate={
                (updatedTopic) => handleTopicUpdate(topic.id, updatedTopic) // Pass topic.id instead of index
              }
            />
          ))}
          <button
            className="bg-blue-100 hover:bg-blue-200 hover:text-blue-600 text-blue-400 font-medium py-2 px-4 rounded"
            onClick={() => {
              const newId = generateUniqueId(parsedTopics);
              handleTopicUpdate(newId, { id: newId, name: "", note: "" });
            }}
          >
            + Add New Topic
          </button>
        </div>
        <h2 className="text-xl mt-12 p-4 bg-slate-200 rounded text-slate-700 inline-block">
          Edit protocol
        </h2>
        <hr></hr>
        {user.web_id && (
          <UserProtocolEditor
            protocol={user.protocol}
            updateProtocol={handleProtocolUpdate}
            user_id={user.web_id}
          />
        )}
        <div className="mb-20"></div>
      </div>
    </Layout>
  );
};

export default User;
