import React, { useState } from "react";
import Button from "./Button";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import CoachSignInButton from "../CoachSignInButton";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-black text-white">
      <div className="container mx-auto flex justify-between items-center">
        <img src="/images/logo-white.png" alt="Logo" className="h-8" />
        <nav className="hidden md:flex space-x-8 xl:space-x-16">
          <a
            href="https://wa.me/+16203372653?text=Hello"
            className="hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            WhatsApp
          </a>
          <a
            href="sms:+16203372653"
            className="hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            SMS
          </a>
          <CoachSignInButton
            buttonText={"Coach sign-in"}
            className="hover:text-gray-400"
          />
        </nav>
        <Button>Try the Coach Live</Button>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3BottomRightIcon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="md:hidden bg-black text-white p-4">
          <a
            href="https://wa.me/+16203372653?text=Hello"
            className="block py-2 hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            WhatsApp
          </a>
          <a
            href="sms:+16203372653"
            className="block py-2 hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            SMS
          </a>
          <a
            href="https://m.me/100093207430260?text=Hello"
            className="block py-2 hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            Messenger
          </a>
          <CoachSignInButton
            buttonText={"For coaches"}
            className="block py-2"
          />
        </nav>
      )}
    </header>
  );
};

export default Header;
