import React from "react";

function SalesLanding() {
  return (
    <div className="max-w-lg flex justify-center flex-col text-center items-center mx-auto p-8 bg-white">
      <div className="flex flex-col md:flex-row justify-center items-center my-16">
        <img
          src="/images/logo.png"
          className="w-32 my-4 md:my-0 md:mr-4"
          alt="1440 Logo"
        />
        <div className="relative px-6 py-2 rounded-full border-gray-300 border-2 border-opacity-40 flex items-center">
          <div className="flex justify-center items-center">
            <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-lightBlue opacity-50"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-accent"></span>
          </div>
          <span className="ml-2 font-medium text-sm opacity-60">
            beta version live
          </span>
        </div>
      </div>
      <div>
        <p className="font-medium mb-8 text-2xl md:text-3xl">
          Coaching, the leading method for effective personal growth, is
          expensive and inaccessible.
        </p>
        <p className="font-medium mb-8 text-2xl md:text-3xl">
          Coach Nia by 1440 is a transformation in coaching, that is proven more
          effective, 10x cheaper and accessible 24/7
        </p>
        <p className="font-medium mb-8 text-2xl md:text-3xl">
          Learn about Nia through Sarah's experience
        </p>
        <div className="text-center mb-8 mt-4">
          <a
            href="https://wa.me/16203372653?text=Hello"
            className="inline-flex items-center justify-center bg-green-500 text-white font-medium text-xl py-3 px-6 rounded-full shadow-lg hover:bg-green-600 transition-colors"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/whatsapp.png"
              alt="WhatsApp"
              className="w-8 h-8 mr-2"
            />
            Or text Nia on WhatsApp
          </a>
        </div>
        <div className="flex justify-center items-center">
          <div className="w-16 h-16 bg-gray-200 rounded-full flex justify-center items-center">
            <img
              src="/images/up-arrow.png"
              className="transform rotate-180 brightness-0 w-6 h-6"
              alt="Up Arrow"
            />
          </div>
        </div>
        <div className="space-y-8 mt-16">
          {/* Card for Deep Understanding and Goal Setting */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex">
              <div className="rounded-lg my-2 p-4 bg-gray-200">
                <img
                  src="/images/goal-setting.png"
                  alt="Goal Setting Icon"
                  className="h-10 w-10"
                />
              </div>
            </div>
            <h2 className="text-left text-3xl font-medium mt-4 text-gray-800 mb-2">
              Deep Understanding and Goal Setting
            </h2>
            <p className="text-left text-2xl text-gray-500 mt-4">
              Meet Sarah, eager to find balance. With Coach Nia, she explores
              her aspirations through comprehensive assessments, setting
              personalized goals together.
            </p>
          </div>
          {/* Arrow between cards */}
          <div className="flex justify-center my-2-center">
            <img
              src="/images/arrow1.png"
              alt="Down Arrow"
              className="h-24 w-24"
            />
          </div>
          {/* Card for Daily Integration3 and Continuous medium */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex">
              <div className="rounded-lg my-2 p-4 bg-gray-200 text-2xl text-gray-500 mt-4">
                <img
                  src="/images/daily-integration.png"
                  alt="Daily Integration Icon"
                  className="h-10 w-10"
                />
              </div>
            </div>
            <h2 className="text-left text-3xl font-medium mt-4 text-gray-800 mb-2">
              Daily Integration and Continuous Support
            </h2>
            <p className="text-left text-2xl text-gray-500 mt-4">
              As Sarah moves through her day, Coach Nia seamlessly integrates
              with her calendar and wearable tech, optimizing her routine for
              productivity and well-being.
            </p>
            <div className="text-left mt-4">
              <a
                href="https://wa.me/16203372653?text=Hello"
                className="inline-flex items-center justify-center bg-green-500 text-white font-medium text-xl py-3 px-6 rounded-lg w-full shadow-lg hover:bg-green-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/whatsapp.png"
                  alt="WhatsApp"
                  className="w-8 h-8 mr-2"
                />
                Text Nia on WhatsApp
              </a>
            </div>
          </div>
          {/* Arrow between cards */}
          <div className="flex justify-center my-2-center">
            <img
              src="/images/arrow2.png"
              alt="Down Arrow"
              className="h-28 w-28"
            />
          </div>
          {/* Card for Adaptive Strategi3es and medium-Time Adjustments */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex">
              <div className="rounded-lg my-2 p-4 bg-gray-200 text-2xl text-gray-500 mt-4-gray-200">
                <img
                  src="/images/adaptive-strategies.png"
                  alt="Adaptive Strategies Icon"
                  className="h-10 w-10"
                />
              </div>
            </div>
            <h2 className="text-left text-3xl font-medium mt-4 text-gray-800 mb-2">
              Adaptive Strategies and Real-Time Adjustments
            </h2>
            <p className="text-left text-2xl text-gray-500 mt-4">
              When facing challenges, Coach Nia provides Sarah with adaptive
              strategies and support, ensuring she remains resilient and aligned
              with her goals.
            </p>
          </div>
          {/* Arrow between cards */}
          <div className="flex justify-center my-2-center">
            <img
              src="/images/arrow4.png"
              alt="Down Arrow"
              className="h-28 w-28"
            />
          </div>
          {/* Card for Proactive and Pre3ventive Interventions medium} */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex">
              <div className="rounded-lg my-2 p-4 bg-gray-200 text-2xl text-gray-500 mt-4">
                <img
                  src="/images/proactive-interventions.png"
                  alt="Proactive Interventions Icon"
                  className="h-10 w-10"
                />
              </div>
            </div>
            <h2 className="text-left text-3xl font-medium mt-4 text-gray-800 mb-2">
              Proactive and Preventive Interventions
            </h2>
            <p className="text-left text-2xl text-gray-500 mt-4">
              Coach Nia anticipates and preempts potential setbacks, offering
              proactive interventions to keep Sarah moving forward.
            </p>
          </div>
          {/* Arrow between cards */}
          <div className="flex justify-center my-2-center">
            <img
              src="/images/arrow5.png"
              alt="Down Arrow"
              className="h-24 w-24"
            />
          </div>
          {/* Card for Empowerment and M3astery */}
          <div className="bg-white shadow-lg rounded-l text-gray-800g p-6">
            <div className="flex">
              <div className="rounded-lg my-2 p-4 bg-gray-200">
                <img
                  src="/images/empowerment.png"
                  alt="Empowerment Icon"
                  className="h-10 w-10"
                />
              </div>
            </div>
            <h2 className="text-left text-3xl font-medium mt-4 text-gray-800 mb-2">
              Empowerment and Mastery
            </h2>
            <p className="text-left text-2xl text-gray-500 mt-4">
              Sarah gains confidence and starts taking on leadership roles, with
              Coach Nia providing guidance based on the latest scientific
              research.
            </p>
            <div className="text-left mt-4">
              <a
                href="https://wa.me/16203372653?text=Hello"
                className="inline-flex items-center justify-center bg-green-500 text-white font-medium text-xl py-3 px-6 rounded-lg w-full shadow-lg hover:bg-green-600 transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/whatsapp.png"
                  alt="WhatsApp"
                  className="w-8 h-8 mr-2"
                />
                Text Nia on WhatsApp
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesLanding;
