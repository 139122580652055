import React, { useState, useEffect, useContext } from "react";
import PhoneInput from "react-phone-number-input";
import { PhoneIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { CoachContext } from "../../../CoachContext";

const AddUserModal = ({ onClose, onAddUser }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [protocols, setProtocols] = useState([]);
  const [selectedProtocol, setSelectedProtocol] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const { coachId } = useContext(CoachContext);

  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  useEffect(() => {
    // Use axiosWithAuth to fetch protocols
    axiosWithAuth()
      .get(`/protocols/${coachId}`)
      .then((response) => {
        let protocols = response.data;
        protocols = protocols.map((protocol) => ({
          title: protocol.title,
          id: protocol._id,
        }));
        setProtocols(protocols);
        setSelectedProtocol(protocols[0].id);
      })
      .catch((error) => {
        console.error("Error fetching protocols:", error);
      });
  }, [coachId]); // Make sure to pass coachId as a dependency if it's a prop

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Send the request to your endpoint
    try {
      const response = await axiosWithAuth().post("/api/coach/add_user", {
        phone: phoneNumber,
        protocol: selectedProtocol,
        message: bodyMessage,
      });
      onAddUser(response.data); // Handle the response accordingly
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
          <label className="text-slate-500 mb-0 pb-0" htmlFor="phoneInput">
            Phone Number
          </label>
          <PhoneInput
            id="phoneInput"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}
            className="w-full border shadow-sm rounded px-3 py-2"
            icons={{ phone: <PhoneIcon className="h-5 w-5 text-gray-500" /> }}
          />
          <label className="text-slate-500 mb-0 pb-0" htmlFor="protocolInput">
            Protocol
          </label>
          <select
            id="protocolInput"
            value={selectedProtocol}
            onChange={(e) => setSelectedProtocol(e.target.value)}
            className="border mt-0 focus:border-indigo-500 focus:ring py-2 px-3 focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm w-full"
          >
            {protocols.map((protocol) => (
              <option key={protocol.id} value={protocol.id}>
                {protocol.title}
              </option>
            ))}
          </select>
          <label className="text-slate-500 mb-0 pb-0" htmlFor="messageInput">
            Message
          </label>
          <textarea
            id="messageInput"
            placeholder="Enter your message"
            value={bodyMessage}
            onChange={(e) => setBodyMessage(e.target.value)}
            className="border-gray-300 border py-2 px-3 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm h-24 w-full "
          />
          <div className="flex justify-start space-x-4">
            <button
              type="submit"
              className="bg-blue-100 hover:bg-blue-300 text-blue-900 font-medium py-2 px-4 rounded"
            >
              Add User
            </button>
            <button
              onClick={onClose}
              className="bg-red-100 hover:bg-red-300 text-red-900 font-medium py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUserModal;
