import React from "react";
import Layout from "./Layout";

const Settings = () => {
  return (
    <Layout title="Settings">
      <div className="">Settings is under development.</div>
    </Layout>
  );
};

export default Settings;
