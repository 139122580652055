import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import "./MessageList.css";

const MessageList = ({ conversation, isDemoActive }) => {
  const messagesEndRef = useRef(null);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [intervalIds, setIntervalIds] = useState([]);

  useEffect(() => {
    for (let id of intervalIds) {
      clearInterval(id);
    }
    setDisplayedMessages([]);
  }, [conversation]);

  useEffect(() => {
    if (isDemoActive) {
      setIsVisible(false);

      const timeoutId = setTimeout(() => {
        setIsVisible(true);
        let index = 0;
        let messageIndex = 0;
        let currentMessage = "";
        const intervalId = setInterval(() => {
          if (messageIndex < conversation.length) {
            const message = conversation[messageIndex];
            if (index < message.content.length) {
              currentMessage += message.content[index];
              setDisplayedMessages((prevMessages) => {
                if (messageIndex < prevMessages.length) {
                  return prevMessages.map((m, idx) =>
                    idx === messageIndex ? { ...m, content: currentMessage } : m
                  );
                } else {
                  return [
                    ...prevMessages,
                    { ...message, content: currentMessage },
                  ];
                }
              });
              index++;
            } else {
              currentMessage = "";
              index = 0;
              messageIndex++;
            }
          } else {
            clearInterval(intervalId);
          }
        }, 40); // Typewriter speed
        setIntervalIds((prevIds) => [...prevIds, intervalId]);
        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
      }, 500); // Fade effect duration
    } else {
      setDisplayedMessages(conversation);
    }
  }, [isDemoActive, conversation]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [displayedMessages]);

  return (
    <div
      className={`messages bg-white mt-2 md:mt-10 text-black dark:bg-black dark:text-white w-full transition-opacity duration-1000 ${
        isDemoActive ? (isVisible ? "opacity-100" : "opacity-0") : "opacity-100"
      }`}
    >
      {displayedMessages.flatMap((msg, index) =>
        msg.content.split("\n").map((contentPart, partIndex) => {
          const isLastCoachMessage =
            msg.role !== "user" && index === displayedMessages.length - 1;
          return (
            <React.Fragment key={`${index}-${partIndex}`}>
              {partIndex === 0 && (
                <div
                  className={`font-bold mt-2 pl-4 border-l dark:border-black light:border-white ${
                    msg.role === "user" ? "text-gray-400" : "text-accent"
                  }`}
                >
                  {msg.role === "user" ? "You" : "Coach Nia"}
                </div>
              )}
              <div
                className={`border-l border-gray-800 pl-4 my-1 leading-tight ${
                  partIndex === 0 ? "mt-1" : "mt-3"
                } ${isLastCoachMessage ? "last-coach-message" : ""}`}
              >
                <ReactMarkdown className="ReactMarkdown">
                  {contentPart}
                </ReactMarkdown>
              </div>
            </React.Fragment>
          );
        })
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;
