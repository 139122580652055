import React, { useState } from "react";
import { format } from "date-fns";

const LogCard = ({ log }) => {
  const [selectedLog, setSelectedLog] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy, HH:mm");
  };

  const handleCardClick = () => {
    setSelectedLog(log);
  };

  const handleCloseModal = (e) => {
    if (e.target.id === "backdrop") {
      setSelectedLog(null);
    }
  };

  return (
    <>
      <div
        className="border border-gray-200 rounded-lg mb-0 cursor-pointer hover:shadow-lg transition-shadow duration-200"
        onClick={handleCardClick}
      >
        <p className="text-gray-600 text-sm p-4 pb-0 line-clamp-2">
          {log.results.split(" - ").join("\n")}
        </p>
        <div className="border-t px-4 border-gray-200 py-3 flex justify-between items-center bg-slate-100">
          <p className="text-sm text-gray-700 mb-0 font-medium">{log.name}</p>
          <p className="text-xs text-gray-500 mb-0">{formatDate(log.date)}</p>
        </div>
      </div>

      {selectedLog && (
        <div
          id="backdrop"
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          onClick={handleCloseModal}
        >
          <div className="bg-white p-8 rounded-lg shadow-lg w-full md:w-1/2 overflow-auto">
            <h2 className="text-2xl font-bold mb-4">{selectedLog.name}</h2>
            <p className="text-gray-600 text-sm whitespace-pre-wrap">
              {selectedLog.results.split(" - ").join("\n")}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default LogCard;
