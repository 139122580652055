import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "../Layout";
import ProtocolOutline from "./ProtocolOutline";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import EditProtocolField from "./EditProtocolField";
import EditTask from "./EditTask";

const EditProtocol = ({
  protocol,
  onClose,
  updateLocalProtocol,
  isUserProtocol = "",
}) => {
  const [protocolData, setProtocolData] = useState(
    Array.isArray(protocol) ? protocol : [protocol]
  );
  const [selectedProtocolIndex, setSelectedProtocolIndex] = useState(0);
  const updateTimeoutId = useRef(null);
  const lastProtocolValue = useRef(null);

  const handleTaskUpdate = (updatedTask) => {
    const updatedTasks = protocolData[selectedProtocolIndex]?.tasks?.map(
      (task) => (task.task_id === updatedTask.task_id ? updatedTask : task)
    );
    handleProtocolChange("tasks", updatedTasks);
  };

  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleAddTask = () => {
    const newTask = {
      description: "",
      execute_at: new Date().toISOString(),
      type: "one-time",
      task_id: Date.now().toString(), // Simple unique ID generator for example purposes
    };
    const updatedTasks = [
      ...protocolData[selectedProtocolIndex].tasks,
      newTask,
    ];
    handleProtocolChange("tasks", updatedTasks);
  };

  // Function to remove a task by task_id
  const handleRemoveTask = (taskId) => {
    const updatedTasks = protocolData[selectedProtocolIndex]?.tasks?.filter(
      (task) => task.task_id !== taskId
    );
    handleProtocolChange("tasks", updatedTasks);
  };

  // This function now handles all updates to the protocol
  const handleProtocolChange = (key, value) => {
    const updatedProtocolData = [...protocolData];
    updatedProtocolData[selectedProtocolIndex] = {
      ...updatedProtocolData[selectedProtocolIndex],
      [key]: value,
    };
    setProtocolData(updatedProtocolData); // Update local state

    // Clear the previous timeout
    if (updateTimeoutId.current) {
      clearTimeout(updateTimeoutId.current);
    }

    // Set the last value to compare after the timeout
    lastProtocolValue.current = value;

    // Set a new timeout
    updateTimeoutId.current = setTimeout(() => {
      // Check if the value is still the same
      if (lastProtocolValue.current === value) {
        if (isUserProtocol) {
          updateUserProtocol(updatedProtocolData[selectedProtocolIndex]);
        } else {
          updateProtocol(updatedProtocolData[selectedProtocolIndex]);
        }
      }
    }, 1800); // 3 seconds timeout
  };

  // Debounced function to update the protocol on the server
  const updateUserProtocol = (updatedProtocol) => {
    axiosWithAuth()
      .post("/user_protocol/update/", {
        protocol_data: updatedProtocol,
        user_id: isUserProtocol,
      })
      .then((response) => {
        if (response.status === 200) {
          updateLocalProtocol(updatedProtocol); // This updates the protocol in the parent component's state
        }
      })
      .catch((error) => {
        console.error("Error updating protocol:", error);
      });
  };

  const updateProtocol = (updatedProtocol) => {
    axiosWithAuth()
      .post("/protocol/update", updatedProtocol)
      .then((response) => {
        if (response.status === 200) {
          updateLocalProtocol(updatedProtocol);
        }
      })
      .catch((error) => {
        console.error("Error updating protocol:", error);
      });
  };

  const renderProtocolEditor = (protocol, index) => {
    return (
      <div key={protocol._id || index}>
        <h3 className="text-lg mt-4 font-medium mb-1 text-slate-700">
          User's protocol outline
        </h3>
        <p className="text-slate-400 text-sm w-2/3">
          The underlying protocol, that will guide the AI in what conversations
          to have, at what stages. This is not completely linear, but an outline
          that will inform the types of things to bring up next.
        </p>
        <ProtocolOutline
          initialItems={protocol?.summary || []}
          onUpdate={(summary) => handleProtocolChange("summary", summary)}
        />
        <div className="mt-12">
          <h1 className="text-xl font-medium text-slate-700 mb-6">
            {isUserProtocol
              ? "Edit user's protocol fields"
              : "Edit protocol fields"}
          </h1>
          {fields.map((field) => (
            <EditProtocolField
              key={field.key}
              title={field.title}
              description={field.description}
              placeholder={field.placeholder}
              value={protocol[field.key] || ""}
              onChange={(e) => handleProtocolChange(field.key, e.target.value)}
            />
          ))}
        </div>
        <div className="mt-12">
          <h1 className="text-lg font-medium text-slate-700 mb-2">
            {isUserProtocol ? "Edit reminders" : "Edit tasks"}
          </h1>
          <p className="text-slate-400 text-sm w-2/3">
            {isUserProtocol
              ? "If the user's phone number is synced, 1440 will proactively send messages based on the description and conversational context."
              : "Define tasks that the AI should perform at specific times."}
          </p>
          {protocol?.tasks &&
            protocol?.tasks?.map((task) => (
              <div key={task.task_id} className="mb-4">
                <EditTask
                  task={task}
                  onUpdate={handleTaskUpdate}
                  handleRemove={() => handleRemoveTask(task.task_id)}
                  isUserTask={isUserProtocol}
                />
              </div>
            ))}
          <button
            onClick={handleAddTask}
            className="mt-4 bg-slate-200 text-slate-500 py-2 px-4 rounded hover:bg-slate-300 mb-8"
          >
            + Add {isUserProtocol ? "reminder" : "task"}
          </button>
        </div>
      </div>
    );
  };

  const fields = [
    {
      key: "title",
      title: "Title",
      description: "Enter the title for the protocol.",
      placeholder: "Enter title here...",
      value: protocolData[selectedProtocolIndex]?.title || "",
    },
    {
      key: "phone",
      title: "Phone number",
      description:
        "The 1440-owned phone number that is associated with this protocol in the format of '+11234567890'. Comma-separate multiple numbers.",
      placeholder: "Phone number",
      value: protocolData[selectedProtocolIndex]?.phone || "",
    },
    {
      key: "personality",
      title: "Personality",
      description: "Customize the personality for the conversation.",
      placeholder: "Describe the personality...",
      value: protocolData[selectedProtocolIndex]?.personality || "",
    },
    {
      key: "coach_name",
      title: "Coach name",
      description: "Customize the coaches name for the conversation.",
      placeholder: "Coach name...",
      value: protocolData[selectedProtocolIndex]?.coach_name || "",
    },
    {
      key: "topic_writing_guide",
      title: "Topic Writing Guide",
      description:
        "At every conversational shift of topic, we generate a guide based on context, and the new topic, about how the coach should approach this new topic. This is the prompt used to generate that guide.",
      placeholder: "How should the coach address each topic?",
      value: protocolData[selectedProtocolIndex]?.topic_writing_guide || "",
    },
    {
      key: "current_module",
      title: isUserProtocol
        ? "Current Conversation Topic"
        : "First Conversation Topic",
      description: isUserProtocol
        ? "Title of the current conversation topic."
        : "Title of the first conversation topic.",
      placeholder: isUserProtocol
        ? "Enter the current topic title..."
        : "Enter the first topic title...",
      value: protocolData[selectedProtocolIndex]?.current_module || "",
    },
    {
      key: "current_prompt",
      title: "Initial Prompt",
      description: isUserProtocol
        ? "Define the initial prompt for the current conversation."
        : "Define the initial prompt for the first conversation.",
      placeholder: isUserProtocol
        ? "What will the coach say now?"
        : "What will the coach say first?",
      value: protocolData[selectedProtocolIndex]?.current_prompt || "",
    },
  ];

  if (isUserProtocol) {
    return (
      <div className="mt-4">
        <div className="flex gap-2 mb-4">
          {protocolData.map((protocol, index) => (
            <button
              key={protocol._id || index}
              className={`py-2 px-4 rounded-lg ${
                index === selectedProtocolIndex
                  ? "bg-blue-500 text-white"
                  : "bg-slate-200 text-slate-500"
              }`}
              onClick={() => setSelectedProtocolIndex(index)}
            >
              {protocol.title || `Protocol ${index + 1}`}
            </button>
          ))}
        </div>
        {protocolData.map((protocol, index) => {
          return (
            index === selectedProtocolIndex &&
            renderProtocolEditor(protocol, index)
          );
        })}
      </div>
    );
  } else {
    return (
      <Layout
        title={protocolData[selectedProtocolIndex]?.title}
        rightElement={
          <button
            onClick={onClose}
            className="flex items-center bg-slate-200 py-2 px-3 rounded-lg text-slate-600"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            All protocols
          </button>
        }
      >
        <div className="flex gap-2 mb-4">
          {protocolData.map((protocol, index) => (
            <button
              key={protocol._id || index}
              className={`py-2 px-4 rounded-lg ${
                index === selectedProtocolIndex
                  ? "bg-blue-500 text-white"
                  : "bg-slate-200 text-slate-500"
              }`}
              onClick={() => setSelectedProtocolIndex(index)}
            >
              {protocol.title || `Protocol ${index + 1}`}
            </button>
          ))}
        </div>
        {protocolData.map((protocol, index) => {
          return (
            index === selectedProtocolIndex &&
            renderProtocolEditor(protocol, index)
          );
        })}
      </Layout>
    );
  }
};

export default EditProtocol;
