import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import { Auth0Provider } from "@auth0/auth0-react";
import Cookies from "js-cookie";

axios.defaults.baseURL = "https://api.1440.ai/";
axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get("1440_access_token"); // Retrieve the token from cookies

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="dev-loxpthsb4ptsnqfz.us.auth0.com"
    clientId="rR8dqiA7yClypyIg8BZwwX9g3CpGFQeQ"
    authorizationParams={{
      redirect_uri: window.location.origin + "/coach/dashboard",
    }}
  >
    <App />
  </Auth0Provider>
);
